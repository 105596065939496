<template>
  <div class="w-full">
    <template v-if="cameras.length">
      <table class="min-w-full">
        <thead>
          <tr>
            <th scope="col" class="px-6 py-3 text-left">Nombre</th>
            <th scope="col" class="px-6 py-3 text-left">Frecuencia</th>
            <th scope="col" class="px-6 py-3 text-left">Sincronizar desde FTP</th>
            <th scope="col" class="px-6 py-3 text-left">Tipo</th>
            <th scope="col" class="px-6 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(camera, i) in cameras" :key="i" :class="{ 'bg-primary-100': i % 2 === 0 }">
            <td scope="col" class="px-6 py-3 text-left">{{ camera.name }}</td>
            <td scope="col" class="px-6 py-3 text-left">{{ camera.capture_interval }} minutos</td>
            <td scope="col" class="px-6 py-3 text-left">
              {{ camera.sync_from_ftp ? 'Sí' : 'No' }}
            </td>
            <td scope="col" class="px-6 py-3 text-left">
              {{ camera.type == 'timelapse' || camera.type == null ? 'Timelapse' : 'IP' }}
            </td>
            <td scope="col" class="px-6 py-3 text-right space-x-4">
              <button
                type="button"
                class="rounded-full h-8 w-8 border border-gray-500 focus:outline-none"
                @click="handlerInfo(camera)"
              >
                <i class="tmlps-i-info text-3xl"></i>
              </button>
              
              <button
                type="button"
                class="rounded-full h-8 w-8  focus:outline-none"
                @click="handlerPhotos(camera)"
              >
                <img :src="iconoCamara" alt="">
              </button>

              <button
                type="button"
                class="rounded-full focus:outline-none"
                @click="handlerEdit(camera)"
              >
                <i class="tmlps-i-edit-circle text-3xl h-8 w-8"></i>
              </button>

              <button
                type="button"
                class="rounded-full focus:outline-none"
                @click="handlerDelete(camera)"
              >
                <i class="tmlps-i-delete-circle text-3xl h-8 w-8"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-start-4 col-span-6 mt-8 mx-auto">
          <img
            src="/img/camera-empty-state.png"
            alt="no-records"
            class="h-50 w-full object-cover md:w-80 mx-auto"
          />
          <h2 class="text-2xl text-center -mt-10">
            No hay cámaras registradas por el momento
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SomeIcon from '@/assets/nuevosIconos/5.svg';
 
export default {
  name: 'cameras-table',
  props: {
    cameras: { type: Array, required: true },
  },
  data() {
    return {
      iconoCamara: SomeIcon
    };
  },
  methods: {
    handlerInfo(camera) {
      this.$emit('show-information', camera);
    },
    handlerPhotos(camera){
      //console.log("🚀 handlerPhotos ~ camera", camera)
      this.$emit('show-photos', camera);
    },
    handlerEdit(camera) {
      this.$emit('edit-camera', camera);
    },
    handlerDelete(camera) {
      this.$emit('delete-camera', camera);
    },
  },
};
</script>
