<template>
  <BaseModal :show="show" @close="$emit('close')" v-if="camera">
    <h3 slot="header" class="font-bold text-base">Editar cámara</h3>

    <form @submit.prevent="handleEdit" id="cameraEditForm">
      <div class="space-y-6">
        <!-- Nombre -->
        <div>
          <BaseInput
            label="Nombre de la cámara"
            placeholder="Nombre de la cámara"
            :error="errors.first('name')"
            v-model="name"
          />
        </div>

        <!-- Zona horaria -->
        <div>
          <TimezoneInput
            label="Zona horaria"
            placeholder="Zona horaria"
            :error="errors.first('timezone')"
            v-model="timezone"
          />
        </div>

        <div class="flex items-stretch justify-between space-x-3">
          <!-- Hora de inicio de captura -->
          <div class="flex-1">
            <label class="font-bold">Hora de inicio de captura</label>
            <datetime
              :id="capture_start_time_id"
              :name="capture_start_time_id"
              format="YYYY-MM-DD HH:mm:ss"
              label="Hora de inicio de captura"
              placeholder="Hora de inicio de captura"
              :error="errors.first('capture_start_time')"
              v-model="capture_start_time"
              class="mt-1"
            />
          </div>

          <!-- Hora de término de captura -->
          <div class="flex-1">
            <label class="font-bold">Hora de término de captura</label>
            <datetime
              :id="capture_end_time_id"
              :name="capture_end_time_id"
              format="YYYY-MM-DD HH:mm:ss"
              label="Hora de término de captura"
              placeholder="Hora de término de captura"
              :error="errors.first('capture_end_time')"
              v-model="capture_end_time"
              class="mt-1"
            />
          </div>
        </div>

        <div class="flex items-stretch justify-between space-x-3">
          <!-- Intervalo de captura -->
          <div class="flex-1">
            <label class="font-bold">&nbsp;&nbsp;</label>
            <BaseInput
              label="Intervalo de captura (segundos)"
              placeholder="Intervalo de captura (segundos)"
              :error="errors.first('capture_interval_seconds')"
              v-model="capture_interval"
              class="mt-5"
            />
          </div>

          <!-- Tiempo de selección preferido -->
          <div class="flex-1">
            <label class="font-bold">Tiempo de selección preferido</label>
            <datetime
              :id="preferred_selection_time_id"
              :name="preferred_selection_time_id"
              format="YYYY-MM-DD HH:mm:ss"
              label="Tiempo de selección preferido"
              placeholder="Tiempo de selección preferido"
              :error="errors.first('preferred_selection_time')"
              v-model="preferred_selection_time"
              class="mt-1"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <p>Mostrar en grid de cámaras</p>
          <BaseSwitch :value="showInGrid" @on="showInGrid = true" @off="showInGrid = false" />
        </div>

        <!-- Tipo de Cámara -->
        <div>
          <SelectInput
            :options="types"
            label="Tipo de Cámara"
            placeholder="Tipo de Cámara"
            :error="errors.first('type')"
            v-model="type"
          />
        </div>

        <div class="flex items-center justify-between" v-if="type == 'timelapse'">
          <!-- Vista pública -->
          <p>Vista pública</p>
          <BaseSwitch
            :value="allow_public"
            @input="handleSwitchInput"
            @on="allow_public = true"
            @off="allow_public = false"
          />
        </div>

        <div class="flex items-center justify-between" v-if="type == 'timelapse'">
          <!-- Generar video automático al final del mes -->
          <!-- <p>Generar video automático al final del mes</p>
          <BaseSwitch
            :value="generate_videos_automatic"
            @input="handleSwitchInput"
            @on="generate_videos_automatic = true"
            @off="generate_videos_automatic = false"
          /> -->
        </div>

        <div class="space-y-6" v-if="generate_videos_automatic">
          <!-- Calidad del video -->
          <div>
            <SelectInput
              :options="qualityList"
              label="Calidad del video"
              placeholder="Calidad del video"
              :error="errors.first('quality')"
              v-model="quality"
            />
          </div>
          <hr />
        </div>

        <div class="flex items-center justify-between" v-if="type == 'timelapse'">
          <!-- Sincronizar FTP -->
          <p>Sincronizar FTP</p>
          <BaseSwitch
            :value="sync_from_ftp"
            @input="handleSwitchInput"
            @on="sync_from_ftp = true"
            @off="sync_from_ftp = false"
          />
        </div>

        <div class="space-y-6" v-if="sync_from_ftp">
          <!-- Eliminar fotos sincronizadas de la fuente -->
          <div class="flex items-center justify-between">
            <p>Eliminar fotos sincronizadas de la fuente</p>
            <BaseSwitch
              :value="delete_synced_photos_from_source"
              @input="handleSwitchInput"
              @on="delete_synced_photos_from_source = true"
              @off="delete_synced_photos_from_source = false"
            />
          </div>

          <!-- Eliminar fotos vacías desde la fuente -->
          <div class="flex items-center justify-between">
            <p>Eliminar fotos vacías desde la fuente</p>
            <BaseSwitch
              :value="delete_empty_photos_from_source"
              @input="handleSwitchInput"
              @on="delete_empty_photos_from_source = true"
              @off="delete_empty_photos_from_source = false"
            />
          </div>

          <!-- Dirección FTP -->
          <div>
            <BaseInput
              label="Dirección FTP"
              placeholder="Dirección FTP"
              :error="errors.first('ftp_address')"
              v-model="ftp_address"
            />
          </div>

          <!-- Puerto FTP -->
          <div>
            <BaseInput
              label="Puerto FTP"
              placeholder="Puerto FTP"
              :error="errors.first('ftp_port')"
              v-model="ftp_port"
            />
          </div>

          <!-- Usuario FTP -->
          <div>
            <BaseInput
              label="Usuario FTP"
              placeholder="Usuario FTP"
              :error="errors.first('ftp_username')"
              v-model="ftp_username"
            />
          </div>

          <!-- Contraseña FTP -->
          <div>
            <BaseInput
              label="Contraseña FTP"
              placeholder="Contraseña FTP"
              :error="errors.first('ftp_password')"
              v-model="ftp_password"
            />
          </div>

          <!-- Directorio FTP -->
          <div>
            <BaseInput
              label="Directorio FTP"
              placeholder="Directorio FTP"
              :error="errors.first('ftp_path')"
              v-model="ftp_path"
            />
          </div>
        </div>

        <!-- Embed Script de Terceros -->
        <div v-if="type == 'third-party'">
          <BaseInput
            :type="'textarea'"
            label="Embed Script"
            placeholder="Embed Script"
            :error="errors.first('embed_script')"
            v-model="embed_script"
          />
        </div>

        <BaseFileInput class="mt-6" label="Imagen de portada" name="cover_image"></BaseFileInput>
      </div>
    </form>

    <div slot="footer" class="mt-6 flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="$emit('close')">
        Cancelar
      </BaseButton>
      <BaseButton
        type="submit"
        form="cameraEditForm"
        class="flex-grow py-3 inline-flex items-center justify-center"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
          </svg>Guardar
      </BaseButton>
    </div>

    <RedirectConfirmationModal
      :show="showRedirectConfirmation"
      :busy="false"
      @confirm="handleRedirectMaps(true)"
      @close="handleRedirectMaps(false)"
    />

  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import uuid from '@/util/Uuid';
import datetime from 'vuejs-datetimepicker';
import BaseButton from '@/components/BaseButton.vue';
import BaseFileInput from '@/components/BaseFileInput.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseModal from '@/components/BaseModal.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import SelectInput from '@/components/SelectInput.vue';
import TimezoneInput from '@/components/TimezoneInput.vue';
import formatYYYYMMDDHHmmss2 from '@/util/formatYYYYMMDDHHmmss2';
import RedirectConfirmationModal from './RedirectConfirmationModal.vue';
export default {
  name: 'camera-edit-modal',
  components: {
    datetime,
    BaseButton,
    BaseFileInput,
    BaseInput,
    BaseModal,
    BaseSwitch,
    SelectInput,
    TimezoneInput,
    RedirectConfirmationModal
  },
  props: {
    show: { type: Boolean, default: false },
    camera: { type: Object, default: null },
  },
  data() {
    return {
      showRedirectConfirmation: false,
      errors: new Errors(),
      loading: false,
      cover_image_old: '',
      capture_start_time_id: uuid(),
      capture_end_time_id: uuid(),
      preferred_selection_time_id: uuid(),
      types: [
        { name: 'Timelapse', value: 'timelapse' },
        { name: 'Terceros', value: 'third-party' },
      ],
      qualityList: [
        { value: 'original', name: 'Original' },
        { value: 'hd', name: 'HD' },
        { value: 'fhd', name: 'full HD' },
        { value: '2k', name: '2k' },
        { value: 'qhd', name: 'QHD' },
      ],
      name: '',
      timezone: null,
      capture_start_time: formatYYYYMMDDHHmmss2(new Date()),
      capture_end_time: formatYYYYMMDDHHmmss2(new Date()),
      capture_interval: '',
      preferred_selection_time: formatYYYYMMDDHHmmss2(new Date()),
      type: null,
      showInGrid: true,
      sync_from_ftp: false,
      allow_public: false,
      generate_videos_automatic: false,
      embed_script: '',
      delete_synced_photos_from_source: false,
      delete_empty_photos_from_source: false,
      ftp_address: '',
      quality: 'original',
      ftp_port: '',
      ftp_username: '',
      ftp_password: '',
      ftp_path: '',
    };
  },
  methods: {
    handleRedirectMaps(valorRedirect){
      if(valorRedirect){
        this.$router.push({ name: 'project-camera-location', params: { camera_id: this.camera.id } });
      }
      else{
        this.showRedirectConfirmation=false;
        this.$emit('close');
        this.$emit('reload-records', true);
      }
    },
    async handleEdit(event) {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('timezone', this.timezone);
      formData.append('capture_start_time', this.capture_start_time);
      formData.append('capture_end_time', this.capture_end_time);
      formData.append('capture_interval_seconds', Number(this.capture_interval));
      formData.append('preferred_selection_time', this.preferred_selection_time);
      formData.append('latitude', this.latitude);
      formData.append('longitude', this.longitude);
      formData.append('show_in_grid', this.showInGrid ? 1 : 0);

      if (
        event.target.elements['cover_image'] &&
        event.target.elements['cover_image'].files.length > 0
      ) {
        formData.append('cover_image_old', this.cover_image_old);
        formData.append('cover_image', event.target.elements['cover_image'].files[0]);
      }

      formData.append('type', this.type);
    
      
      if (this.type == 'third-party') {
        this.sync_from_ftp = false;
        this.allow_public = false;
        this.generate_videos_automatic = false;
        console.log("🚀 ~ file: EditModal.vue:387 ~ handleEdit ~ this.type:",this.type)
        console.log("🚀 ~ file: EditModal.vue:387 ~ handleEdit ~ this.embed_script:",this.embed_script)

        formData.append('embed_script', this.embed_script);
      }

      formData.append('sync_from_ftp', Number(this.sync_from_ftp));
      formData.append('allow_public', Number(this.allow_public));
      formData.append('generate_videos_automatic', Number(this.generate_videos_automatic));
      
      if(this.generate_videos_automatic){
        formData.append('quality', this.quality);
      }

      if (this.sync_from_ftp) {
        formData.append(
          'delete_synced_photos_from_source',
          Number(this.delete_synced_photos_from_source),
        );
        formData.append(
          'delete_empty_photos_from_source',
          Number(this.delete_empty_photos_from_source),
        );
        formData.append('ftp_address', this.ftp_address);
        formData.append('ftp_port', this.ftp_port);
        formData.append('ftp_username', this.ftp_username);
        formData.append('ftp_password', this.ftp_password);
        formData.append('ftp_path', this.ftp_path);
      }

      this.loading = true;
      this.errors.clear();

      try {
        const camera = await this.$store.dispatch('camera/updateCamera', {
          cameraId: this.camera.id,
          data: formData,
        });
        this.clearForm();
        // this.$emit('close');
        this.$emit('created', camera);
        this.$store.dispatch('notification/addSuccess', 'La cámara ha sido editado correctamente');
        //this.$router.push({ name: 'project-camera-location', params: { camera_id: camera.id } });
        this.showRedirectConfirmation=true;
      } catch (error) {
        console.log("🚀 ~ handleEdit ~ error:", error)
        if (error.response && error.response.data.errors) {
          this.$store.dispatch('notification/addError', error.response.data.errors);
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.name = '';
      this.latitude = '';
      this.longitude = '';
      this.cover_image_old = '';
      this.timezone = null;
      this.capture_start_time_id = null;
      this.capture_start_time = null;
      this.capture_end_time_id = null;
      this.capture_end_time = null;
      this.capture_interval_seconds = null;
      this.preferred_selection_time_id = null;
      this.preferred_selection_time = null;
      this.sync_from_ftp = false;
      this.allow_public = false;
      this.delete_synced_photos_from_source = false;
      this.delete_empty_photos_from_source = false;
      this.type = null;
      this.embed_script = '';
      this.ftp_address = '';
      this.ftp_port = '';
      this.ftp_username = '';
      this.ftp_password = '';
      this.ftp_path = '';

      this.generate_videos_automatic = false;
      this.quality = 'original';
    },
    handleSwitchInput() {},
  },
  watch: {
    camera: function(value) {
      //console.log('edit camera', value);
      this.name = value.name;
      this.latitude = value.latitude;
      this.longitude = value.longitude;
      this.cover_image_old = value.cover_image || '';
      this.timezone = value.timezone;
      this.capture_start_time = formatYYYYMMDDHHmmss2(value.capture_start_time);
      this.capture_end_time = formatYYYYMMDDHHmmss2(value.capture_end_time);
      this.capture_interval = String(value.capture_interval * 60);
      this.preferred_selection_time = formatYYYYMMDDHHmmss2(value.preferred_selection_time);
      this.sync_from_ftp = value.sync_from_ftp;
      this.allow_public = value.allow_public;

      this.generate_videos_automatic = value.generate_videos_automatic || false;
      this.quality = value.quality || 'original';


      this.delete_synced_photos_from_source = value.delete_synced_photos_from_source;
      this.delete_empty_photos_from_source = value.delete_empty_photos_from_source;
      this.type = value.type;
      this.showInGrid = value.show_in_grid;
      this.embed_script = value.embed_script || null;
      this.ftp_address = value.ftp_address;
      this.ftp_port = value.ftp_port;
      this.ftp_username = value.ftp_username;
      this.ftp_password = value.ftp_password;
      this.ftp_path = value.ftp_path;
      this.capture_start_time_id = uuid();
      this.capture_end_time_id = uuid();
      this.preferred_selection_time_id = uuid();
    },
    type: function(value) {
      if (value != 'timelapse') {
        this.sync_from_ftp = false;
        this.allow_public = false;
        this.generate_videos_automatic = false;
        this.delete_synced_photos_from_source = false;
        this.delete_empty_photos_from_source = false;
        this.ftp_address = '';
        this.ftp_port = '';
        this.ftp_username = '';
        this.ftp_password = '';
        this.ftp_path = '';
      } else {
        this.embed_script = '';
      }
    },
  },
};
</script>
