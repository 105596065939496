<template>
  <div>
    <div class="flex items-center justify-between border-b pb-4">
      <h3 class="font-bold">
        {{ project.current.cameras_count }}
        cámaras
      </h3>
      <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
        Agregar cámara
      </BaseButton>
    </div>

    <div class="mt-5">
      <div class="flex flex-wrap -m-4">
        <cameras-table
          :cameras="project.current.cameras"
          @show-information="handlerInformation"
          @show-photos="handlerFotos"
          @edit-camera="handlerEdit"
          @delete-camera="handlerDelete"
          @reload-records="reloadData"
        />
      </div>
    </div>

    <!-- CREATE CAMERA -->
    <camera-create-modal
      :show="showCreateModal"
      :project="project.current"
      @close="showCreateModal = false"
      @created="reloadData"
    />

    <!-- INFO CAMERA -->
    <camera-information-modal
      :show="showInfoModal"
      :camera="current"
      @close="showInfoModal = false"
    />

    <!-- FOTOS CAMERA showPhotosModal -->
    <PhotoSelectorDrawer
      :camera="current"
      v-if="showPhotosModal"
      :show="showPhotosModal"
      v-model="selectedPhoto"
      :mostrarDiaAnterior="mostrarDiaAnterior"
      close-on-select-photo
      @close="showPhotosModal = false"
    />

    <!-- EDIT CAMERA -->
    <camera-edit-modal
      :show="showEditModal"
      :camera="camera.current"
      @close="showEditModal = false"
      @reload-records="reloadData"
    />

    <!-- DELETE CAMERA -->
    <camera-delete-confirmation-modal
      :show="showDeleteModal"
      :camera="current"
      @close="showDeleteModal = false"
      @reload-records="reloadData"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';
import CamerasTable from './components/Table';
import CameraEditModal from './components/EditModal';
import CameraCreateModal from './components/CreateModal';
import CameraInformationModal from './components/InformationModal';
import CameraDeleteConfirmationModal from './components/DeleteConfirmationModal';
import PhotoSelectorDrawer from '@/components/PhotoSelectorDrawer.vue';
export default {
  name: 'projects-detail',
  components: {
    BaseButton,
    CamerasTable,
    CameraEditModal,
    CameraCreateModal,
    CameraInformationModal,
    CameraDeleteConfirmationModal,
    PhotoSelectorDrawer
  },
  data() {
    return {
      showCreateModal: false,
      showInfoModal: false,
      showPhotosModal: false,
      showDeleteModal: false,
      showEditModal: false,
      selected: null,
      selectedPhoto: null,
      mostrarDiaAnterior: false,
    };
  },
  computed: {
    ...mapState(['project', 'camera']),
    current() {
      return this.selected;
    },
  },
  methods: {
    async handlerEdit(camera) {
      this.$store.commit('camera/SET_CURRENT_CAMERA', camera);
      this.showEditModal = true;
    },
    handlerDelete(camera) {
      this.selected = camera;
      this.showDeleteModal = true;
    },
    handlerInformation(camera) {
      this.selected = camera;
      this.showInfoModal = true;
    },
    handlerFotos(camera){
      this.selected = camera;
      this.showPhotosModal = true;
    },
    async reloadData(reload) {
      if (reload) await this.$store.dispatch('project/fetchProjectById', this.project.current.id);
    },
  },
};
</script>
