<template>
  <BaseModal :show="show" @close="$emit('close')" v-if="camera">
    <h3 slot="header" class="font-bold text-base">Información de la cámara</h3>

    <div class="space-y-6">
      <!-- Zona horaria -->
      <div>
        <p>Zona horaria</p>
        <strong>{{ camera.timezone }}</strong>
      </div>

      <div class="flex items-stretch justify-between space-x-3">
        <!-- Hora de inicio de captura -->
        <div class="flex-1">
          <p>Hora de inicio de captura</p>
          <strong>{{ camera.capture_start_time }}</strong>
        </div>

        <!-- Hora de término de captura -->
        <div class="flex-1">
          <p>Hora de término de captura</p>
          <strong>{{ camera.capture_end_time }}</strong>
        </div>
      </div>

      <div class="flex items-stretch justify-between space-x-3">
        <!-- Intervalo de captura -->
        <div class="flex-1">
          <p>Intervalo de captura</p>
          <strong>{{ camera.capture_interval }} minutos</strong>
        </div>

        <!-- Tiempo de selección preferido -->
        <div class="flex-1">
          <p>Tiempo de selección preferido</p>
          <strong>{{ camera.preferred_selection_time }}</strong>
        </div>
      </div>

      <!-- Tipo de Cámara -->
      <div>
        <p>Tipo de cámara</p>
        <strong>{{ camera.type }}</strong>
      </div>

      <div class="space-y-6" v-if="camera.sync_from_ftp">
        <!-- Eliminar fotos sincronizadas de la fuente -->
        <div>
          <p>Eliminar fotos sincronizadas de la fuente</p>
          <strong>{{ camera.delete_synced_photos_from_source ? 'Sí' : 'No' }}</strong>
        </div>

        <!-- Eliminar fotos vacías desde la fuente -->
        <div>
          <p>
            Eliminar fotos vacías desde<br />
            la fuente
          </p>
          <strong>{{ camera.delete_empty_photos_from_source ? 'Sí' : 'No' }}</strong>
        </div>
      </div>

      <div class="flex items-stretch justify-between space-x-3" v-if="camera.sync_from_ftp">
        <!-- Dirección FTP -->
        <div class="flex-1">
          <p>Dirección FTP</p>
          <strong>{{ camera.ftp_address }}</strong>
        </div>

        <!-- Puerto FTP -->
        <div class="flex-1">
          <p>Puerto FTP</p>
          <strong>{{ camera.ftp_port }}</strong>
        </div>
      </div>

      <div class="flex items-stretch justify-between space-x-3" v-if="camera.sync_from_ftp">
        <!-- Usuario FTP -->
        <div class="flex-1">
          <p>Usuario FTP</p>
          <strong>{{ camera.ftp_username }}</strong>
        </div>

        <!-- Contraseña FTP -->
        <div class="flex-1">
          <p>Contraseña FTP</p>
          <strong>{{ camera.ftp_password }}</strong>
        </div>
      </div>

      <div class="flex items-stretch justify-between space-x-3" v-if="camera.sync_from_ftp">
        <!-- Directorio FTP -->
        <div class="flex-1">
          <p>Directorio FTP</p>
          <strong>{{ camera.ftp_path }}</strong>
        </div>
      </div>

      <!-- Embed Script de Terceros -->
      <div v-if="camera.type == 'third-party'">
        <p>Embed Script de Terceros</p>
        <div
          class="border bg-gray-200 rounded p-5 mt-3 font-mono text-xs font-extralight break-all"
        >
          {{ camera.embed_script }}
        </div>
      </div>

      <div v-if="camera.cover_image_url">
        <p>Imagen de fondo</p>
        <img :src="coverImageUrl" class="w-full mt-2" alt="imagen de fondo" />
      </div>
    </div>

    <div slot="footer" class="flex items-stretch justify-between space-x-3 mt-4">
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="$emit('close')"
      >
        Cerrar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';

export default {
  name: 'camera-information-modal',
  components: {
    BaseButton,
    BaseModal,
  },
  props: {
    show: { type: Boolean, default: false },
    camera: { type: Object, default: null },
  },
  computed: {
    coverImageUrl() {
      return this.camera.cover_image_url;
    },
  },
};
</script>
