<template>
  <Drawer :active="show" @close="$emit('close')">
    <template slot="header">
      <div class="photo-selection__title">
        <slot name="titledrawer">Elige una fecha</slot>
      </div>
    </template>

    <div class="photo-selection__content">
      <div class="photo-selection__datepicker">
        <VueCtkDateTimePicker
          inline
          only-date
          format="YYYY-MM-DD"
          :disabled-dates="camera.dates_without_photos"
          :max-date="maxDate"
          :min-date="minDate"
          no-header
          no-button
          no-shortcuts
          no-label
          no-keyboard
          color="var(--primary)"
          locale="es"
          @input="fetchPhotos"
          :value="formattedDate"
        />
        <BaseButton :disabled="photo_for_delete.length == 0" class="h-10 w-40 ml-3" @click="showDeleteConfirmation = true">
          Eliminar imágenes
        </BaseButton>
      </div>
      <div class="photo-selection__divider"></div>
      <div class="photo-selection__photos" id="divAbajo">
       
        <div
          class="d-flex justify-content-center align-items-center h-100"
          v-if="!loading && photos.length === 0"
        >
          <span>Sin fotos en la fecha elegida</span>
        </div>
        <div
          v-else
          :class="{ 'align-items-center justify-content-center': photos.length === 0, 'photo-selection__photos-wrapper': !loading }"
        >
          <div v-if="loading">
              ....
          </div>
          <div
            :style="loading ? `visibility: hidden;` : ''"
            class="photo-selection__photo"
            :class="{
              'photo-selection__photo--active': photo_for_delete.find(x => x.id === photo.id) ? true : false,
            }"
            @click="handleSelectPhoto(photo)"
            v-for="photo in photos"
            :key="photo.id"
          >
            <img loading="lazy" :src="photo.optimized_url" :alt="photo.name" />
            <p class="photo-selection__photo-time">{{ formatCaptureTime(photo.captured_at) }}</p>
            <svg class="photo-selection__photo-check" fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <PhotoDeleteConfirmationModal
      :show="showDeleteConfirmation"
      :busy="deletingReport"
      @confirm="handleDeleteReport"
      @close="showDeleteConfirmation = false"
    />
  </Drawer>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import dateUtil from '@/util/date';
import Drawer from '@/components/Drawer';
import PhotoDeleteConfirmationModal from '@/components/PhotoDeleteConfirmationModal.vue';
import BaseButton from '@/components/BaseButton';
export default {
  name: 'PhotoSelectorDrawer',
  components: {
    Drawer,
    VueCtkDateTimePicker,
    PhotoDeleteConfirmationModal,
    BaseButton
  },

  props: {
    camera: { type: Object, required: true },
    date: { type: Date, default: () => new Date() },
    show: { type: Boolean, default: false },
    closeOnSelectPhoto: { type: Boolean, default: false },
    value: { type: Object },
    mostrarDiaAnterior:{}
  },

  data() {
    return {
      selectedDate: this.date,
      photos: [],
      tiempoTranscurrido: Date.now(),
      hoy:null,
      loading: true,
      showDeleteConfirmation: false,
      deletingReport: false,
      photo_for_delete: []
    };
  },

  watch: {
    date: {
      immediate: true,
      handler: function(newValue) {
        this.fetchPhotos(newValue);
      },
    },
    mostrarDiaAnterior:{
      immediate: true,
      handler: function(newValue) {
        if(this.mostrarDiaAnterior){
          this.fetchPhotos(this.selectedDate, newValue);
        }
      },
    }
  },

  computed: {
    minDate() {
      return this.camera.first_capture_at
        ? dateUtil.toDateString(new Date(this.camera.first_capture_at))
        : dateUtil.toDateString(new Date());
    },
    maxDate() {
      return this.camera.last_capture_at
        ? dateUtil.toDateString(new Date(this.camera.last_capture_at))
        : dateUtil.toDateString(new Date());
    },
    formattedDate() {
      //console.log('dateUtil', dateUtil.toDateString(this.selectedDate));
      return dateUtil.toDateString(this.selectedDate);
    },
  },

  methods: {
    dateDisabled(ymd) {
      return this.datesWithoutPhotos.includes(ymd);
    },
    handleSelectPhoto(photo) {
      //console.log("🚀 ~ file: PhotoSelectorDrawer.vue:153 ~ handleSelectPhoto ~ photo", photo)

        //si ya existe el id en el array para eliminar lo removemos
        const encontrado = this.photo_for_delete.findIndex(x => x.id === photo.id)
        //console.log("🚀 ~ encontrado", encontrado)
        if(encontrado >= 0){
          this.photo_for_delete.splice(encontrado, 1);
        }else{
          this.photo_for_delete.push(photo); 
        }
        //console.log("🚀 ~ this.photo_for_delete", this.photo_for_delete)

        
        //this.$emit('input', photo);

        //   if (this.closeOnSelectPhoto) {
        //     this.$emit('close');
        //   }

        //SE PREGUNTA si se desea elimnar la foto
        //this.photo = photo;
        //this.showDeleteConfirmation = true;

    },
    async handleDeleteReport() {
      this.deletingReport = true;
      this.loading = true;
      try {

        for(let i in this.photo_for_delete){
          const foto = this.photo_for_delete[i];
          await this.$store.dispatch('photo/deletePhoto', foto.id);
        }
        



        
        await this.fetchPhotos(this.selectedDate, false);
        this.photo_for_delete = [];
        this.showDeleteConfirmation = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.deletingReport = false;
      }
    },
    formatCaptureTime(date) {
      return new Date(date).toLocaleTimeString(undefined, {
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',
      });
    },
    async fetchPhotos(date, diaAnterior=false) {
      //console.log("🚀 fetchPhotos ~ CambiaAlDia", diaAnterior)
      //console.log('fetchPhotos', date);

      let dateObject = date;

      if (!(date instanceof Date)) {
        dateObject = new Date(date + 'T00:00:00');
      }

      if(diaAnterior == 'diaAnterior'){
        dateObject = new Date(dateObject);
        dateObject.setDate(dateObject.getDate() - 1);
      }else if(diaAnterior ==  'diaSiguiente'){
        dateObject = new Date(dateObject);
        dateObject.setDate(dateObject.getDate() + 1);
      }
     
      this.hoy = new Date(this.tiempoTranscurrido);
      //console.log(this.hoy.toLocaleDateString(),"es hoy");
      //console.log(dateObject.toLocaleDateString());
      dateObject.toLocaleDateString() < this.hoy.toLocaleDateString() ? console.log("si es menor a hoy") : 'no es menor';
      this.selectedDate = dateObject;
      this.loading = true;
      this.photos = [];
      //console.log(dateObject,"hora");
      this.photos = await this.$store.dispatch('photo/fetchPhotos', {
        camera: this.camera,
        startDate: dateUtil.startOfDay(dateObject),
        endDate: dateUtil.endOfDay(dateObject),
        page: 1,
      });
      //console.log("🚀 ~ fetchPhotos ~ this.photos", this.photos)

      
      let posicionMarkSelectFoto=0;
      if(diaAnterior == 'diaAnterior'){
        posicionMarkSelectFoto = this.photos.length-1;
        await this.sleep(3000);
        this.scrollToElement();
      }
      
      this.$emit('input', this.photos[posicionMarkSelectFoto] ?? null);

      
      this.loading = false;
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    scrollToElement() {

      var container = this.$el.querySelector("#divAbajo");
      container.scrollTop = container.scrollHeight;
    
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.photo-selection {
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 0;
    height: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__divider {
    background-color: #c1c1c1;
    display: none;

    @include media-breakpoint-up(md) {
      margin: 0 1rem;
      height: 100%;
      display: block;
      width: 1px;
      flex-shrink: 0;
    }
  }

  &__photos {
    overflow-y: auto;
    margin-top: 1rem;
    padding-bottom: 1rem;
    flex: 1;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    img {
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 100px;
        width: 180px;
      }
    }
  }

  &__photos-wrapper {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
    }
  }

  &__photo {
    $self: &;
    margin-bottom: 1.25rem;
    cursor: pointer;
    position: relative;

    @include media-breakpoint-up(md) {
      margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    }

    &--active {
      #{ $self }-check {
        display: block;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__photo-check {
    display: none;
    height: 20x;
    width: 20px;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    fill: rgba(78, 207, 59, 0.8);
    color: white;
    background-color: white;
    border-radius: 100%;
  }

  &__photo-time {
    font-size: 0.875rem;
    padding-top: 0.25rem;
  }
}
</style>
