export default {
  /**
   * Format a date as "yyyy-mm-dd"
   *
   * @param {Date} date The date to format
   */
  toDateString(date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (!date) {
      date = new Date();
    }

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
  },

  toLocalDate(date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (!date) {
      date = new Date();
    }

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  },

  /**
   * Parse a date string in ISO format to a date object.
   *
   * @param {string} date the date to parse in ISO format yyyy-mm-dd
   */
  parseDate(date) {
    const [year, month, day] = date.split('-');

    return new Date(year, month - 1, day);
  },

  /**
   *
   * @param {Date} date
   */
  startOfDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  },

  endOfDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  },

  toHumanString(date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (!date) {
      date = new Date();
    }

    let options = { weekday: 'long', month: 'long', day: 'numeric' };

    return new Date(date).toLocaleDateString('es-ES', options);
  },

  getYear(date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (!date) {
      date = new Date();
    }

    return date.getFullYear();
  },
};
