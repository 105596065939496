<template>
  <div class="relative block">
    <div
      class="border border-gray-500 overflow-hidden rounded-md input-content transition duration-200 ease-linear"
      :class="{ 'border-primary': focused && !error, 'border-red-600': error }"
    >
      <label
        class="absolute text-xs bg-white p-1 -top-3 left-9 transition duration-200 ease-linear"
        :class="{ 'text-primary': focused && !error, 'text-red-600': error }"
        v-if="value"
        >{{ label }}</label
      >

      <div class="flex items-center justify-center">
        <slot name="left-side"></slot>

        <select
          class="h-12 pl-3 border-none text-sm flex-1 placeholder-gray-400 focus:outline-none focus:ring-0"
          :value="value"
          v-bind="$attrs"
          @focus="focused = true"
          @blur="focused = false"
          @input="$emit('input', $event.target.value)"
        >
          <option value="">{{ label }}</option>
          <option v-for="(option, i) in options" :key="i" :value="option.value">
            {{ option.name }}
          </option>
        </select>

        <slot name="right-side" :error="error" :focused="focused"></slot>
        <svg
          v-if="error"
          class="h-4 w-4 text-red-600 stroke-current mr-4"
          height="16"
          width="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="text-sm text-red-600 pl-10 mt-1" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'SelectInput',
  inheritAttrs: false,
  props: {
    label: { type: String, default: '' },
    value: { type: String },
    error: { type: String, default: null },
    options: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    filled() {
      return this.value.length > 0;
    },
  },
};
</script>
