<template>
  <BaseDrawer :active="active">
    <div class="drawer__container">
      <div name="header" class="drawer__header">
        <slot name="header"></slot>
        <button class="drawer__close" tabindex="-1" @click="$emit('close')">
          X
        </button>
      </div>
      <div class="drawer__content">
        <slot></slot>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/BaseDrawer.vue';

export default {
  name: 'Drawer',
  components: { BaseDrawer },
  props: {
    active: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.drawer__container {
  display: flex;
  flex-direction: column;
  background: white;
  height: 100%;
  width: 100%;
}

.drawer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 1rem;

  @include media-breakpoint-up(md) {
    padding: 1rem 2.5rem;
  }
}

.drawer__content {
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  margin-top: 1rem;
}

.drawer__close {
  background-color: transparent;
  border: none;
  color: #484848;
  padding: 0;
}
</style>
